import React, { useState } from 'react';
import Layout from '../components/Layout';
import CHAS from '../img/CHAS.jpg';
import MediSave from '../img/Medisave.png';
import styled from 'styled-components';

const DivContainer = styled.div`
  max-width: 800px;
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
  @media screen and (max-width: 800px) {
    max-width: 500px;
  }
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`;

const OurServices = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Layout>
      <div
        className="content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '50px',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <DivContainer>
          <h1 className="has-text-centered">Our Services</h1>
          <ul>
            <li>MOH Appointed Yellow Fever Vaccination Centre</li>
            <li>Travel Vaccinations</li>
            <li>Occupational Medicine Advice</li>
            <li>Pre-Employment Check Ups</li>
            <li>Corporate Health Screening Programme</li>
            <li>Workplace Health Programmes (educational talks etc)</li>
            <li>Individual Customised Health Screening</li>
            <li>General Consultations</li>
            <li>Acute and Chronic Diseases Treatment & Management</li>
            <li>
              Part of <strong>CHAS</strong> and <strong>MediSave</strong>{' '}
              schemes
            </li>
          </ul>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={CHAS} alt="CHAS" width="50px" height="52.5px" />
            <img
              src={MediSave}
              alt="Medisave"
              width="70.6px"
              height="50px"
              style={{ marginLeft: '20px' }}
            />
          </div>
        </DivContainer>
        <DivContainer>
          <h1 className="has-text-centered" style={{ marginTop: '50px' }}>
            Health Screening Programmes
          </h1>
          <p>
            Regular physical exams and health screening tests form an essential
            part of preventive health care for the early detection of common
            conditions like heart disease, diabetes,hypertension and cancer
            which can be treated and managed appropriately. We offer the
            following comprehensive health screening packages tailored to your
            specific needs:
          </p>
          <ul>
            <li>
              <strong>Basic Package:</strong> A started screening package
              recommended for those 25-30 years old
            </li>
            <li>
              <strong>Executive Package:</strong> Focuses on maintaining health
              in the prime of your life, recommended for those 31-39 years old
            </li>
            <li>
              <strong>Platinum Package:</strong> Focuses on health risks that
              may come with middle age, recommended for those 40 years old and
              above. You are also welcome to make individual requests for tests
              not included in these packages.
            </li>
          </ul>
          <p>
            Contact us for more details regarding our Health Screening
            Programmes
          </p>
          <p>
            Health screening can be customized according to each individual's
            health profile and medical history. As well for corporates, these
            can be customised according to each company’s healthcare
            requirements and/or specific industry’s needs.
          </p>
          <p>
            Please do not hesitate to call our hotline{' '}
            <strong>
              <a href="tel:+6562259813">6225 9813</a>
            </strong>{' '}
            to make an appointment or for any further enquiries.
          </p>
        </DivContainer>

        <DivContainer>
          <h3 className="has-text-centered">
            Preparing For Your Health Screening
          </h3>
          <ul>
            <li>
              Fast for 10 Hours before the blood test. Plain water is allowed
            </li>
            <li>
              Postpone routine morning medicine until blood tests are completed
            </li>
            <li>
              Pap smear, urine and stool tests for ladies should be done 7 days
              after the last day of menstruation
            </li>
          </ul>
        </DivContainer>
      </div>
    </Layout>
  );
};

export default OurServices;
